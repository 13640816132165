/**
Custom DSFR classes.
*/

:root {
  --color-validation-background-A_VALIDER: var(--yellow-moutarde-975-75);
  --color-validation-background-hover-A_VALIDER: #FEECD3;
  --color-validation-icon-A_VALIDER: var(--yellow-moutarde-main-679);

  --color-validation-background-A_RENSEIGNER: var(--pink-macaron-975-75);
  --color-validation-background-hover-A_RENSEIGNER: #FEE3DD;
  --color-validation-icon-A_RENSEIGNER: var(--pink-tuile-main-556);

  --color-validation-background-VALIDE: var(--green-emeraude-975-75);
  --color-validation-background-hover-VALIDE: #CEFDDC;
  --color-validation-icon-VALIDE: var(--green-emeraude-main-632);

  --color-box-background: #F9F8F6;
  --color-box-background-hover: #E4E3E1;
}

.xfr-tabs__panel--fix {
  z-index: 1000;
}

.xfr-display-inline-block {
  display: inline-block;
}

.xfr-display-none {
  display: none;
}

.xfr-display-block {
  display: block;
}

@mixin display($layout, $breakpoint ) {
  @media (min-width: #{$breakpoint}em) {
    .xfr-display-#{$layout}-none {
      display: none
    }

    .xfr-display-#{$layout}-block {
      display: block
    }

  }
}

@mixin flexDirection($layout, $breakpoint ) {
  @media (min-width: #{$breakpoint}em) {
    .xfr-flex-direction-#{$layout}-column {
      display: flex;
      flex-direction: column !important;
    }

    .xfr-flex-direction-#{$layout}-row {
      display: flex;
      flex-direction: row !important;
    }
  }
}

@include display("xs", 0);
@include display("sm", 36);
@include display("md", 48);
@include display("lg", 62);
@include display("xl", 78);

@include flexDirection("xs", 0);
@include flexDirection("sm", 36);
@include flexDirection("md", 48);
@include flexDirection("lg", 62);
@include flexDirection("xl", 78);



